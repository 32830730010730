import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Elements
import ButtonSecondaryTransparent from 'components/elements/Buttons/ButtonSecondaryTransparent'

// Images
import LinkedInIcon from 'img/linkedin.inline.svg'

interface MembersProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Members: React.FC<MembersProps> = ({ fields }) => {
  const {
    allWpMember,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.membersQueryQuery>(graphql`
    query membersQuery {
      allWpMember(sort: { order: ASC, fields: menuOrder }) {
        edges {
          node {
            id
            title
            uri
            menuOrder
            memberdetail {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 400)
                  }
                }
              }
              position
              mail
              linkedin
            }
          }
        }
      }
    }
  `)

  const posts: unknown = allWpMember.edges

  return (
    <section className="mb-5 pb-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="members"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

const PostBanner = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
`

const Image = styled(Plaatjie)`
  @media (min-width: 1199px) {
    height: 335px;
    width: 250px;
  }
  @media (max-width: 991px) {
    height: 320px;
    width: 250px;
  }
  @media (max-width: 575px) {
    height: 280px;
    width: 230px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.giga};
  }
  & p {
    font-size: ${({ theme }) => theme.font.size.huge};
  }
`

const PostsContainer = styled.div`
  @media (min-width: 992px) {
    margin-top: -6rem;
  }
  @media (max-width: 991px) {
    margin-top: -2rem;
  }
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const [isHover, setHover] = useState<number | null>(null)

  const [management, setManagement] = useState([])

  const blogBink = useBlogBink()
  
  return (
    <>
      <PostBanner className="px-lg-5 pb-lg-5 p-3">
        <div className="px-lg-5 pb-lg-5">
          <div className="py-5">
            <Content content={fields.description} className="pb-3" />
          </div>
        </div>
      </PostBanner>
      <PostsContainer className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="row">
              {blogBink.posts.map((post, index) => {
                const hovered: boolean = index === isHover

                const { node }: any = post

                if (index === 0) {
                  return (
                    <>
                      <div
                        key={blogBink.posts[blogBink.posts.length - 1].node.id}
                        className="col-sm-6 col-lg-4 col-xl-3 mb-sm-4 d-flex justify-content-center"
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(null)}
                      >
                        <BlogGridPost
                          node={blogBink.posts[blogBink.posts.length - 1].node}
                          current={hovered}
                          blogFields={fields}
                        />
                      </div>
                      <div
                        key={blogBink.posts[0].node.id}
                        className="col-sm-6 col-lg-4 col-xl-3 mb-sm-4 d-flex justify-content-center"
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(null)}
                      >
                        <BlogGridPost
                          node={blogBink.posts[0].node}
                          current={hovered}
                          blogFields={fields}
                        />
                      </div>
                    </>
                  )
                }

                if (index !== blogBink.posts.length - 1) {
                  return (
                    <div
                      key={post.node.id}
                      className="col-sm-6 col-lg-4 col-xl-3 mb-sm-4 d-flex justify-content-center"
                      onMouseEnter={() => setHover(index)}
                      onMouseLeave={() => setHover(null)}
                    >
                      <BlogGridPost
                        node={node}
                        current={hovered}
                        blogFields={fields}
                      />
                    </div>
                  )
                }

                return null
              })}
            </div>
          </div>
        </div>

        {blogBink.showMoreButton && (
          <div className="mt-5 text-center">
            <ButtonSecondaryTransparent to="/" isCustom>
              <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
            </ButtonSecondaryTransparent>
          </div>
        )}
      </PostsContainer>
    </>
  )
}

const PostWrapper = styled.div`
  margin-bottom: 8rem !important;
  & h2 {
    color: ${({ theme }) => theme.color.light};
    font-size: ${({ theme }) => theme.font.size.large};
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }

  & span {
    color: ${({ theme }) => theme.color.secondary};
    font-size: ${({ theme }) => theme.font.size.big};
  }
`

const Title = styled.div`
  margin-bottom: -6rem;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.primary};
  & .job {
    color: ${({ theme }) => theme.color.secondary} !important;
  }

  & a:hover {
    text-decoration: underline;
  }
`

const LinkedIn = styled.div`
  top: 10px;
  left: 10px;

  & a {
    font-size: 0;
  }

  & svg path {
    fill: white;
    transition: all 0.3s ease-in-out;
  }

  &:hover svg path {
    opacity: 0.8;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: {
    id: string
    title: string
    uri: string
    memberdetail: {
      image: any
      position: string
      linkedin: string
      mail: string
    }
  }
  current: boolean
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => (
  <PostWrapper>
    <motion.div className="position-relative">
      <Image image={node.memberdetail.image} alt="" className="" />
      <Title className="position-absolute p-3" style={{ minHeight: 120 }}>
        <h2>{node.title}</h2>
        <p className="job mb-0">{node.memberdetail.position}</p>
        <p className="text-white" style={{ minHeight: 23 }}>
          <a
            href={`mailto:${node.memberdetail.mail}`}
            target="_blank"
            rel="noreferrer"
          >
            {node.memberdetail.mail}
          </a>
        </p>
      </Title>
      <LinkedIn className="position-absolute">
        <a href={node.memberdetail.linkedin} target="_blank" rel="noreferrer">
          <LinkedInIcon />
          Linkedin
        </a>
      </LinkedIn>
    </motion.div>
  </PostWrapper>
)

export default Members

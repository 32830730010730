import React from 'react'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimaryBig'
import ButtonSecondaryTransparent from 'components/elements/Buttons/ButtonSecondaryTransparent'
import ParseContent from 'components/shared/ParseContent'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'
import BlockDefault from 'components/elements/Blocks/BlockDefault'
import Plaatjie from '@ubo/plaatjie'

const Wrapper = styled.section`
  position: relative;
  z-index: 3;
  @media (min-width: 576px) {
    margin-top: -12.5rem;
  }
`

interface StoriesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Stories: React.FC<StoriesProps> = ({ fields }) => {
  const {
    allWpStory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.storiesQueryQuery>(graphql`
    query storiesQuery {
      allWpStory(sort: { order: DESC, fields: date }) {
        edges {
          node {
            id
            title
            uri
            storydetail {
              description
              author
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 1000)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts: unknown = allWpStory.edges

  return (
    <Wrapper className="mb-5 pb-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="stories"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          <BlogFilters />
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </Wrapper>
  )
}

const StyledBlogFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
  font-weight: ${(props) =>
    props.selected
      ? props.theme.font.weight.bold
      : props.theme.font.weight.light};
`

interface BlogFiltersProps {}

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => {
  const blogBink = useBlogBink()

  return (
    <div className="container d-flex">
      {blogBink.categories.map((category: BlogFilterProps) => (
        <StyledBlogFilter
          key={category.node.id}
          id={category.node.id}
          selected={blogBink.isSelected(category.node.id)}
          className="mr-3"
        >
          {category.node.name}
        </StyledBlogFilter>
      ))}
    </div>
  )
}

const PostWrapper = styled.div`
  & svg {
    width: 25px;
    height: 25px;
  }
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row justify-content-center mx-0">
        <div className="col-lg-10">
          <div className="row justify-content-end">
            {blogBink.posts.map((post) => {
              const { node }: any = post

              return (
                <PostWrapper
                  key={post.node.id}
                  className="col-lg-9 d-flex justify-content-center my-5"
                >
                  <BlogGridPost node={node} blogFields={fields} />
                </PostWrapper>
              )
            })}
          </div>
        </div>
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonSecondaryTransparent to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonSecondaryTransparent>
        </div>
      )}
    </div>
  )
}

const ImageWrapper = styled.div`
  position: absolute;
  @media (max-width: 991px) {
    top: -75px;
    right: 0;
  }
  @media (max-width: 575px) {
    right: -5px;
  }
`

const ImageInner = styled.div`
  position: relative;
`

const Image = styled(Plaatjie)`
  margin-left: -120px;
  height: 100%;

  @media (min-width: 992px) {
    width: 300px;
    height: 300px;
  }
  @media (max-width: 991px) {
    width: 170px;
    height: 170px;
  }
  @media (max-width: 575px) {
    width: 130px;
    height: 130px;
  }
`

const CircleWrapper = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  ${({ theme }) => css`
    background: ${theme.color.gradient.to};
    background: ${`rgb(${theme.color.gradient.to})`};
    background: ${`linear-gradient(90deg, rgba(${theme.color.gradient.from}, 1) 0%, rgba(${theme.color.gradient.to}, 1) 100%);`};
  `};
  width: 180px;
  height: 180px;
  @media (min-width: 992px) {
    bottom: -20px;
    left: -200px;
  }

  @media (max-width: 991px) {
    width: 130px;
    height: 130px;
    padding: 2rem;
    right: 75px;
    bottom: -20px;
  }
`

const CircleContent = styled(ParseContent)`
  & p,
  & span {
    line-height: 25px;
    font-size: ${({ theme }) => theme.font.size.large} !important;
    font-weight: ${({ theme }) => theme.font.weight.regular};
    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.medium} !important;
    }
  }
`

const Excerpt = styled.div`
  position: relative;
  z-index: 2;
`

const Content = styled(ParseContent)`
  & p,
  & span {
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }
  overflow: hidden;
  text-overflow: ellipss;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  @media (min-width: 576px) {
    -webkit-line-clamp: 4;
  }
  @media (max-width: 575px) {
    -webkit-line-clamp: 3;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: {
    id: string
    title: string
    uri: string
    storydetail: {
      author: string
      description: string
      image: any
    }
  }
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => {
  const { storydetail } = node
  return (
    <BlockDefault
      noHoverEffect
      className="position-relative row flex-row align-items-center justify-content-end p-md-5"
    >
      <ImageWrapper>
        <ImageInner className="d-flex justify-content-end justify-content-lg-start">
          <CircleWrapper className="rounded-circle">
            <CircleContent content={node.storydetail.author} />
          </CircleWrapper>
          <Image
            image={node.storydetail.image}
            className="rounded-circle"
            alt=""
          />
        </ImageInner>
      </ImageWrapper>
      <Excerpt className="col-lg-9 mt-5 mt-lg-0 pt-5 pt-md-0">
        <div className="p-sm-4">
          <h2 className="mb-4">{node.title}</h2>
          <Content content={storydetail.description} className="ps-lg-1" />
        </div>
        <div className="d-flex justify-content-end mt-4">
          <ButtonPrimary to={node.uri}>{blogFields.readmoretext}</ButtonPrimary>
        </div>
      </Excerpt>
    </BlockDefault>
  )
}

export default Stories
